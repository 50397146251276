<template>
  <div>
    <div
      v-if="rtf"
      class="rtf-text"
    >
      <span
        v-if="text"
      >
        {{ text.length > 250 ? `${text.substring(0, 250)}&hellip;` : text }}
      </span>
      <span
        v-else
        class="rtf-no-text"
      >
        No text preview.
      </span>
    </div>
    <div
      class="rtf-actions"
    >
      <span
        v-if="rtf"
        class="rtf-view"
        @click="onViewRtfClicked"
      >
        {{ 'View' }}
      </span>
      <span
        v-if="rtf"
        class="rtf-download"
        @click="onDownloadRtfClicked"
      >
        {{ 'Download' }}
      </span>
      <label
        v-if="edit"
      >
        <span
          class="rtf-upload"
        >
          {{ rtf ? 'Replace' : 'Upload RTF document' }}
        </span>
        <input
          type="file"
          accept="application/rtf, text/rtf"
          hidden
          @change="onUploadRtf($event)"
        >
      </label>
    </div>
    <modal
      :enabled="viewEnabled"
      type="sheets"
      :title="name"
      info="The overview might not be complete."
      secondary-button-text="Close"
      :styles="{ secondaryType: 'primary' }"
      @secondaryClicked="viewEnabled = false"
      @closeModal="viewEnabled = false"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="html" />
    </modal>
  </div>
</template>

<script>
import {
  ref, watch,
} from 'vue';

import Modal from '@/components/shared/modal.vue';

import { EMFJS, RTFJS, WMFJS } from 'rtf.js';

EMFJS.loggingEnabled(false);
RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);

export default {
  components: {
    Modal,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
    rtf: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['rtfUploaded'],

  setup(props, { emit }) {
    const html = ref(''); // html preview, see watcher further down
    const text = ref(''); // text preview

    const viewEnabled = ref(false); // modal

    const onViewRtfClicked = () => {
      viewEnabled.value = true;
    };

    const onDownloadRtfClicked = () => {
      const blob = new Blob([props.rtf || ''], { type: 'application/rtf;charset=utf-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${props.name}.rtf`;
      link.click();
      URL.revokeObjectURL(link.href);
    };

    const getFileContent = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (readerResult) => {
        resolve(readerResult.target.result);
      };
      reader.onerror = (readerError) => {
        reject(readerError);
      };
      reader.readAsText(file, 'utf-8');
    });

    const onUploadRtf = async (event) => {
      const file = event.target.files[0];
      const content = await getFileContent(file);
      emit('rtfUploaded', content);
    };

    watch(() => props.rtf, async () => {
      try {
        const rtf = props.rtf || '';
        const buffer = new ArrayBuffer(rtf.length);
        const bufferView = new Uint8Array(buffer);
        for (let i = 0; i < rtf.length; i += 1) {
          bufferView[i] = rtf.charCodeAt(i);
        }
        const doc = new RTFJS.Document(buffer);
        // const meta = doc.metadata();
        const htmlElements = await doc.render();
        const div = document.createElement('div');
        div.append(...htmlElements);
        html.value = div.innerHTML || '';
        text.value = div.innerText || div.textContent || '';
      } catch (err) {
        html.value = '';
        text.value = '';
      }
    }, { immediate: true });

    return {
      html,
      text,
      viewEnabled,
      onViewRtfClicked,
      onDownloadRtfClicked,
      onUploadRtf,
    };
  },
};
</script>

<style scoped>
.rtf-text {
  margin-bottom: 0.75rem;
}

.rtf-no-text {
  color: var(--colour-text-lightest);
}

.rtf-actions {
  font-size: var(--font-size-50);
  font-weight: var(--font-weight-bold);
  color: var(--colour-text-default);
}

.rtf-view,
.rtf-download,
.rtf-upload {
  margin-right: 1.25rem;
  cursor: pointer;
}

.rtf-view::before {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  vertical-align: top;
  cursor: pointer;
  content: '';
  background-image: url("../../assets/svg/document-magnifying-glass.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.rtf-download::before {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  vertical-align: top;
  cursor: pointer;
  content: '';
  background-image: url("../../assets/svg/document.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.rtf-upload::before {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  vertical-align: top;
  cursor: pointer;
  content: '';
  background-image: url("../../assets/svg/document-upload.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
